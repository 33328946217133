<template>
  <CButton
    :variant="carrierName"
    :href="returnData.carrier.location_picker_url"
    icon-right="external"
    tag="a">
    {{ $t('returns.carrier_location_button') }}
  </CButton>
</template>

<script>
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import { mapState } from 'vuex';

export default {
  name: 'ReturnsLocationPicker',
  components: {
    CButton: () => import('@/components/CButton'),
  },

  computed: {
    ...mapState(STORE_MODULE_RETURNS, ['returnData']),

    carrierName() {
      return this.returnData.carrier.name;
    },
  },
};
</script>
